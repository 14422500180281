/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { object, bool } from 'prop-types';
import { compose } from 'recompose';
import LinkListDesktop from './Desktop/LinkListDesktop';
import LinkListMobile from './Mobile/LinkListMobile';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';

class LinkList extends PureComponent {
    render() {
        const { data, type, isBot } = this.props;
        return (
            <DesktopMobile
                desktop={() => <LinkListDesktop data={data} isBot={isBot} />}
                mobile={() => <LinkListMobile data={data} type={type} />}
            />
        );
    }
}

LinkList.propTypes = {
    isBot: bool,
    data: object.isRequired,
    type: object,
};

LinkList.defaultProps = {
    isBot: false,
    type: '',
};
const mapStateToProps = (state) => ({
    isBot: getIsBot(state),
});
const enhance = compose(
    connect(mapStateToProps),
);
export default enhance(LinkList);
