/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import object, {
    string, shape, bool, func,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = ({ palette }) => ({

    menuNavFooter: {
        color: palette.black,
        '&:not(nth-child(1))': {
            borderTop: `1px solid ${palette.cms.linkListBorderTop || '#ccc'}`,
        },
        '& details': {
            display: 'block',
            margin: '0px 10px',
            lineHeight: '0',
            borderBottom: `1px solid ${palette.cms.linkListBorderBottom || '#595959'}`,
            '& p': {
                padding: '0',
                margin: '0',
                fontSize: '1.1em',
                lineHeight: '1em',
            },
        },
        '& details[open]': {
            '& summary:after': {
                content: '"-"',
                fontSize: '2em',
                margin: '-0.5em 0.35em 0 0',
            },
            '& summary ~ *': {
                animation: 'open 0.3s ease-in-out',
            },
        },
        '& summary': {
            padding: '0',
            lineHeight: '0',
            fontSize: '1em',
            fontWeight: '700',
            '& > div': {
                display: 'flex',
                justifyContent: 'space-between',
                padding: '3px 0px',
                width: '95%',
            },
            '&::-webkit-details-marker': {
                listStyle: 'none',
                fontSize: '0',
            },
            '&:after': {
                content: '"+"',
                color: palette.black,
                position: 'absolute',
                fontSize: '1.5em',
                lineHeight: '0',
                margin: '-0.7em 0.5em 0 0',
                right: '0',
                fontWeight: '200',
            },
        },
    },
    '@keyframes open': {
        '0%': {
            opacity: '0',
        },
        '100%': {
            opacity: '1',
        },
    },
    link: {
        display: 'block',
        fontSize: '1em',
        padding: '.5em',
        color: palette.black,
        textDecoration: 'none',
    },
    linkTitle: {
        fontSize: '1em',
        fontWeight: 'normal',
    },
    linkTitleBottom: {
        margin: '0px 10px',
        borderBottom: `1px solid ${palette.cms.linkListBorderBottom || '#595959'}`,
        padding: '6px 0',
        width: '95%',
        '& $link': {
            padding: 0,
        },
    },
    heading: {
        margin: '0 0 5px 10px',
    },
});

class LinkListContainer extends PureComponent {
    renderMobileLinks = () => {
        const {
            data, classes, type = '', trackEvent,
        } = this.props;

        const linkData = data;
        const links = data.reference?.[0].links || null;
        const heading = data.reference?.[0].heading || null;

        if (!linkData || type !== 'footermenu' || !links) {
            return null;
        }

        const getTabIndex = (k) => parseInt(k, 10) + 1;
        const structuredLinks = () => {
            /* @intention: Convert a flat list of links into an object
             * with a heirarchy. Also duplicates parent links as child links
             * as to generate a clickable link for the parent category.
             * @return: {obj}
             */
            if (!links) return [];
            // clone links array to prevent error when navigating back
            const linkClone = JSON.parse(JSON.stringify(links));
            const linkStructure = [];
            let parentIndex = 0;
            linkClone.forEach((link, i) => {
                if (!link.link.is_child) {
                    linkStructure[i] = [link];
                    linkStructure[i].child = [];
                    parentIndex = i;
                    // reassign param to include parent link in navigable links
                    // eslint-disable-next-line no-param-reassign
                    // link.link.is_child = true;
                    linkStructure[i].child.push(link);
                } else {
                    linkStructure[parentIndex].child[0].link.is_child = true;
                    linkStructure[parentIndex].child.push(link);
                }
            });
            return linkStructure;
        };

        return (
            <>
                {heading && <h3 className={classes.heading}>{heading}</h3>}
                {
                    Object.entries(structuredLinks()).map(([key, value]) => (
                        !value[0].link?.is_child
                            ? (
                                <Typography className={`${classes.linkTitle} ${classes.linkTitleBottom} `} variant="h6">
                                    <Link
                                        key={key}
                                        className={classes.link}
                                        title={value[0].link?.link?.title}
                                        tabIndex={getTabIndex(key)}
                                        to={value[0].link?.link?.href}
                                        onClick={() => {
                                            trackEvent({
                                                eventCategory: value[0].link?.tracking_event_category || '',
                                                eventAction: value[0].link?.tracking_event_action || '',
                                                eventLabel: value[0].link?.tracking_event_label || '',
                                                eventName: 'feature_interaction', // Requird for GA4 feature_click
                                            });
                                        }}
                                    >
                                        {value[0].link?.link?.title}
                                    </Link>
                                </Typography>
                            )
                            : (
                                <div className={classes.menuNavFooter}>
                                    <details className={classes.details}>
                                        <summary className={classes.summary}>
                                            <div tabIndex={getTabIndex(key)}>
                                                <Typography className={classes.linkTitle} variant="h6">
                                                    {value[0].link?.link?.title}
                                                </Typography>
                                            </div>
                                        </summary>
                                        <p>
                                            {value.child.length && Object.entries(value.child).map(([key2, value2]) => (
                                                <div>
                                                    <Link
                                                        key={key2}
                                                        className={classes.link}
                                                        title={value2.link?.link?.title}
                                                        tabIndex={getTabIndex(key2)}
                                                        to={value2.link?.link?.href}
                                                        onClick={() => {
                                                            trackEvent({
                                                                eventCategory: value2.link?.tracking_event_category || '',
                                                                eventAction: value2.link?.tracking_event_action || '',
                                                                eventLabel: value2.link?.tracking_event_label || '',
                                                                eventName: 'feature_interaction', // Requird for GA4 feature_click
                                                            });
                                                        }}
                                                    >
                                                        {value2.link?.is_child && ' - '}{value2?.link?.link?.title}
                                                    </Link>
                                                </div>
                                            ))}
                                        </p>
                                    </details>
                                </div>
                            )
                    ))
                }
            </>
        );
    }

    render() {
        return this.renderMobileLinks();
    }
}

LinkListContainer.propTypes = {
    classes: object,
    data: shape({
        orientation: string.isRequired,
        presentation: string.isRequired,
        links: shape({
            link: shape({
                add_icon: bool.isRequired,
                is_child: bool.isRequired,
                link: shape({
                    href: string.isRequired,
                    title: string.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    type: string,
    trackEvent: func.isRequired,
};
LinkListContainer.defaultProps = {
    type: '',
    classes: {},
};
const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(LinkListContainer));
