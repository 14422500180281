/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
// import { Link } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import object, {
    func, array, bool, string, shape,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import generateValidCss from '../../../../helpers/contentstack/generateValidCss';
import { handleOnClick } from '../utils/pathUtils';

const styles = () => ({
    bot: {
        display: 'none',
    },
    link: {
        '&:hover': {
            opacity: '.9',
        },
    },
    hlist: {
        alignSelf: 'stretch',
        display: 'flex',
    },
    hlink: {
        padding: '8px 14px',
        cursor: 'pointer',
        width: 'auto',
        minWidth: '120px',
        maxWidth: '190px',
        alignItems: 'center',
        display: 'flex',
    },
    hcopy: {
        height: 'auto',
        width: '100%',
        textAlign: 'center',
    },
    vlink: {
        zIndex: '1',
        textAlign: 'left',
        cursor: 'pointer',
    },
    vlist: {
        display: 'block',
        width: '300px',
        margin: 'auto auto auto 0',
        textAlign: 'left',
        cursor: 'pointer',
    },
});

function LinkListContainer(props) {
    const {
        data, history, classes, trackEvent, isBot,
    } = props;
    const linkData = data?.link_list?.reference?.[0] || data;
    if (!linkData) {
        return null;
    }
    const links = linkData.links;
    if (!links) {
        return null;
    }
    const link_styles = linkData.styles;
    const mainStyle = generateValidCss(linkData.styles, true);
    const renderAsButtons = linkData.presentation === 'Buttons';

    if (linkData.orientation === 'Vertical') {
        mainStyle.zIndex = '1';
        mainStyle.width = 'fit-content';
        mainStyle.maxWidth = '400px';
        mainStyle.minWidth = '200px';
        mainStyle.textAlign = 'left';
        mainStyle.padding = '.5em 1em';
        mainStyle.boxSizing = 'border-box';
        if (renderAsButtons) {
            mainStyle.marginLeft = 'auto';
            mainStyle.marginRight = 'auto';
        }
    } else {
        mainStyle.zIndex = '1';
        mainStyle.textAlign = 'center';
        mainStyle.padding = '.5em 1em';
        mainStyle.width = '100%';
        mainStyle.boxSizing = 'border-box';
        mainStyle.background = 'transparent';
    }
    const getVerticalLinkStyle = (child, i) => {
        const linkStyle = generateValidCss(link_styles, true);
        linkStyle.cursor = 'pointer';
        linkStyle.padding = '.1em .3em';
        if (renderAsButtons) {
            linkStyle.fontSize = '1.2em';
            linkStyle.fontWeight = '400';
            linkStyle.textAlign = 'center';
            if (i) {
                linkStyle.marginTop = '.5em';
            }
            return linkStyle;
        }
        if (child) {
            linkStyle.fontSize = '.95em';
            linkStyle.textIndent = '1em';
            linkStyle.fontWeight = '400';
            linkStyle.marginLeft = '0';
        } else {
            linkStyle.fontSize = '1.2em';
            linkStyle.fontWeight = '400';
            if (i > 0) {
                linkStyle.borderTop = 'solid 1px #ccc';
                // linkStyle.marginTop = '.5em';
            }
            linkStyle.marginLeft = '0';
        }
        return linkStyle;
    };
    const getHorizontalLinkStyle = () => {
        const linkStyle = generateValidCss(linkData.link_styles, true);
        linkStyle.padding = '.3em .8em';
        linkStyle.color = mainStyle.color;
        linkStyle.backgroundColor = mainStyle['background-color'];
        return linkStyle;
    };

    const getTabIndex = (k) => parseInt(k, 10) + 1;

    // Check to see if we want to render a Horizontal Menu
    if (linkData.orientation === 'Horizontal') {
        return (
            <Grid container spacing={4} justify="center" alignItems="center" style={mainStyle}>
                {Object.entries(links).map(([key, value]) => (
                    <>
                        {isBot && (
                            <Link
                                className={classes.bot}
                                to={value?.link?.link?.href}
                                title={value?.link?.link?.title}
                            >{value?.link?.link?.title}
                            </Link>
                        )}
                        <Grid item key={key} className={classes.hlist}>
                            <div
                                className={classes.hlink}
                                style={getHorizontalLinkStyle()}
                                title={value?.link?.link?.title}
                                spacing={4}
                                tabIndex={getTabIndex(key)}
                                role="link"
                                onClick={(e) => {
                                    trackEvent({
                                        eventCategory: value?.link?.tracking_event_category || '',
                                        eventAction: value?.link?.tracking_event_action || '',
                                        eventLabel: value?.link?.tracking_event_label || '',
                                        eventName: 'feature_interaction', // Requird for GA4 feature_click
                                    });
                                    handleOnClick(e, history.push(value?.link?.link?.href), history);
                                }}
                            >
                                <div className={classes.hcopy}>{value?.link?.link?.title}</div>
                            </div>
                        </Grid>
                    </>
                ))}
            </Grid>
        );
    }

    return ( // Render Vertical Menus
        <div style={mainStyle} className="linkList" tabIndex={getTabIndex(-1)}>
            {linkData.heading && <h3>{linkData.heading}</h3>}
            {Object.entries(links).map(([key, value]) => (
                <>
                    {isBot && (
                        <Link
                            className={classes.bot}
                            to={value?.link?.link?.href}
                            title={value?.link?.link?.title}
                        >{value?.link?.link?.title}
                        </Link>
                    )}
                    <div
                        key={key}
                        className={styles?.link}
                        title={value?.link?.link?.title}
                        style={getVerticalLinkStyle(value?.link?.is_child, key)}
                        tabIndex={getTabIndex(key)}
                        role="link"
                        onClick={(e) => {
                            trackEvent({
                                eventCategory: value?.link?.tracking_event_category || '',
                                eventAction: value?.link?.tracking_event_action || '',
                                eventLabel: value?.link?.tracking_event_label || '',
                                eventName: 'feature_interaction', // Requird for GA4 feature_click
                            });
                            handleOnClick(e, history.push(value?.link?.link?.href), history);
                        }}
                    >
                        {value?.link?.link?.title}
                    </div>
                </>
            ))}
        </div>
    );
}

LinkListContainer.propTypes = {
    classes: object.isRequired,
    data: shape({
        links: shape({
            link: shape({
                add_icon: bool,
                is_child: bool,
                tracking_event_action: string,
                tracking_event_category: string,
                tracking_event_label: string,
                link: shape({
                    href: string,
                    title: string,
                }).isRequired,
            }),
        }).isRequired,
        orientation: 'string',
        presentation: 'string',
        styles: array,
    }).isRequired,
    history: object.isRequired,
    trackEvent: func.isRequired,
    isBot: bool,
};
LinkListContainer.defaultProps = {
    isBot: false,
};
const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(withRouter(LinkListContainer)));
