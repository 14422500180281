/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// eslint-disable-next-line import/prefer-default-export
export const handleOnClick = (e, path, history) => {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    e.persist();
    e.stopPropagation();
    history.push(path);
};
